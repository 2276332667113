import React, { useContext, useState } from "react";
import { Collapse } from "@mui/material";
import { RefContext } from "../contexts/RefContextProvider";

const Topbar = () => {
  const [open, setOpen] = useState(false);
  const { aboutRef, roadmapRef, platformsRef } = useContext(RefContext);
  const navigate = async (ref) => {
    var headerOffset = 150;
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
  return (
    <nav className="flex flex-wrap md:flex-row md:flex-nowrap justify-between md:justify-start items-center bg-white md:bg-transparent py-[10px] md:py-[15px] px-5 4lg:px-[10%] 1lg:pt-[10px] 1lg:pr-[10%] 1lg:pb-[10px] 1lg:pl-[20%] min-h-[109px] z-[11] relative">
      <a href="/" className="inline-block py-[0.3125rem] mr-4 text-[1.25rem]">
        <div className="flex justify-center items-center">
          <img className="h-[80px]" src="images/logo.png" alt="" />
        </div>
      </a>
      <button className="block md:hidden py-1 px-3 text-[1.25rem] leading-[1] bg-transparent text-black3 border border-black4 rounded-[0.25rem]" onClick={() => setOpen(!open)}>
        <span className="inline-block w-[1.5em] h-[1.5em] navbar-toggler-icon"></span>
      </button>
      <div className="block md:hidden basis-[100%]">
        <Collapse in={open}>
          <div className="flex flex-col pl-0 mb-0 mx-auto">
            <div className="flex items-center text-white text-[18px]">
              <button className="text-black px-0 py-2" onClick={() => navigate(aboutRef)}>About</button>
            </div>
            <div className="flex items-center text-white text-[18px]">
              <button className="text-black px-0 py-2" onClick={() => navigate(roadmapRef)}>Roadmap</button>
            </div>
            <div className="flex items-center text-white text-[18px]">
              <button className="text-black px-0 py-2" onClick={() => navigate(platformsRef)}>Platforms</button>
            </div>
            <div className="text-black px-0 py-2">
              <div className="flex justify-center items-center px-[10px] text-[16px] leading-[16px] bg-green1 h-[30px] ml-[1.125rem] rounded-[20px] whitespace-nowrap text-white">Launch Immortal Lottery</div>
            </div>
            <div className="flex justify-between mx-auto w-[100px]">
              <a target="_blank" rel="noreferrer" href="https://twitter.com/CallistoSupport" className="text-green1 px-0 py-2">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#34C88A" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a>
              <a target="_blank" rel="noreferrer" href="https://t.me/CallistoNet" className="text-green1 px-0 py-2">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" color="#34C88A" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="hidden md:flex mx-auto">
        <div className="flex items-center text-white text-[18px] px-0 3lg:px-[10px]">
          <button className="py-2 px-4 md:px-2" onClick={() => navigate(aboutRef)}>About</button>
        </div>
        <div className="flex items-center text-white text-[18px] px-0 3lg:px-[10px]">
          <button className="py-2 px-4 md:px-2" onClick={() => navigate(roadmapRef)}>Roadmap</button>
        </div>
        <div className="flex items-center text-white text-[18px] px-0 3lg:px-[10px]">
          <button className="py-2 px-4 md:px-2" onClick={() => navigate(platformsRef)}>Platforms</button>
        </div>
        <div className="px-4 py-2 md:px-2">
          <div className="flex justify-center items-center bg-green1 text-[16px] leading-[16px] h-[30px] rounded-[20px] px-[10px] 3lg:px-[30px] whitespace-nowrap text-white ml-[1.125rem]">Launch Immortal Lottery</div>
        </div>
      </div>
      <div className="hidden md:flex text-white">
        <a target="_blank" rel="noreferrer" href="https://twitter.com/CallistoSupport" className="py-2 px-4">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#FFF" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
        </a>
        <a target="_blank" rel="noreferrer" href="https://t.me/CallistoNet" className="py-2 px-4">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" color="#FFF" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
        </a>
      </div>
    </nav>
  );
};

export default Topbar;
