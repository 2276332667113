import React, { useContext } from "react";
import { RefContext } from "../contexts/RefContextProvider";

const Footer = () => {
  const { aboutRef, roadmapRef, platformsRef } = useContext(RefContext);
  const navigate = async (ref) => {
    var headerOffset = 150;
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
  return (
    <footer className="w-full py-[50px] px-5 1lg:px-[8%] bg-black2">
      <div className="flex flex-col items-center md:flex-row md:items-start justify-between w-full h-full p-0">
        {/* <div className="col-lg-3 col-md-4 col-sm-12 relative w-full px-[15px]">
          <div className="text-center md:text-left text-white font-RobotoB text-[14px] 1lg:text-[18px] py-[10px] leading-[26.25px] whitespace-pre-line">Subscribe to our newsletter</div>
          <div className="flex justify-center md:justify-start flex-wrap w-full mx-0 relative">
            <div className="mt-[10px] md:m-0 border border-green1 w-[60%] h-[42px]">
              <input className="text-[18px] outline-none border-none text-white font-Lato bg-transparent font-medium w-full py-2 px-[10px]" type="text" placeholder="Your email" pattern="^(0x[a-fA-F0-9]{40})$" />
            </div>
            <button className="mt-[10px] md:mt-0 border-none no-underline bg-green1 px-[15px] font-medium h-[42px]">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" color="#FFF" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z" clip-rule="evenodd"></path></svg>
            </button>
          </div>
        </div> */}
        {/* <div className="block md:hidden w-full h-[1px] bg-grey1 my-5" /> */}
        <div className="col-lg-6 col-md-3 col-sm-12 relative w-full px-[15px]">
          <div className="text-center text-white font-RobotoB text-[14px] 1lg:text-[18px] py-[10px] leading-[26.25px] whitespace-pre-line">Join our community</div>
          <div className="flex justify-center flex-wrap w-full mx-0 relative">
            <div className="flex justify-center items-center w-[40px] h-[40px] bg-white rounded-[20px] m-[10px] md:mt-0 md:ml-0 md:mb-0 md:mr-[10px]">
              <a className="text-black2" href="https://t.me/CallistoNet" target="_blank" rel="noreferrer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
              </a>
            </div>
            <div className="flex justify-center items-center w-[40px] h-[40px] bg-white rounded-[20px] m-[10px] md:mt-0 md:ml-0 md:mb-0 md:mr-[10px]">
              <a className="text-black2" href="https://twitter.com/CallistoSupport" target="_blank" rel="noreferrer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a>
            </div>
          </div>
        </div>
        <div className="block md:hidden w-full h-[1px] bg-grey1 my-5" />
        <div className="col-lg-6 col-md-3 col-sm-12 relative w-full px-[15px]">
          <div className="flex flex-row justify-center md:flex-col 2lg:flex-row 2lg:space-x-5">
            <button className="px-[5px] text-center md:text-left text-white font-RobotoB text-[14px] 1lg:text-[18px] py-[10px] leading-[26.25px] whitespace-pre-line" onClick={() => navigate(aboutRef)}>About</button>
            <button className="px-[5px] text-center md:text-left text-white font-RobotoB text-[14px] 1lg:text-[18px] py-[10px] leading-[26.25px] whitespace-pre-line" onClick={() => navigate(roadmapRef)}>Roadmap</button>
            <button className="px-[5px] text-center md:text-left text-white font-RobotoB text-[14px] 1lg:text-[18px] py-[10px] leading-[26.25px] whitespace-pre-line" onClick={() => navigate(platformsRef)}>Platforms</button>
            {/* <a target="_blank" rel="noreferrer" href="https://callisto.network/callisto-airdrop/" className="px-[5px] text-center md:text-left text-white font-RobotoB text-[14px] 1lg:text-[18px] py-[10px] leading-[26.25px] whitespace-pre-line">Airdrop</a> */}
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] my-5 md:mt-[50px] md:mb-5 mx-0 bg-grey1" />
      <div className="flex justify-center md:justify-start items-center flex-wrap w-full mx-0 relative">
        <p className="font-Lato text-white text-[16px] leading-[25px]">© All rights reserved by</p>
        <p className="font-RobotoB text-white text-[16px] leading-[25px] pl-[5px]">Callisto Enterprise s.r.o.</p>
      </div>
    </footer>
  );
};

export default Footer;
