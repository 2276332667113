import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class ReactSlick extends Component {
	render() {
		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 1,
			initialSlide: 0,
			arrows: false,
			draggable: true,
			accessibility: false,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
			],
		};

		return (
			<Slider {...settings}>
				{this.props?.images.map((item, index) => (
					<div>{item}</div>
				))}
			</Slider>
		);
	}
}